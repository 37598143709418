import * as React from "react"
import "./PerspectiveNavigation.css"

const PerspectiveNavigation = () => {
    return(
    <div className="backgroundImage">
    <div className="title">
      <h1 >Perspectives&nbsp;</h1>
    <p className="p_tag">Home &nbsp; {">"} &nbsp;  Perspectives&nbsp;&nbsp;&nbsp;</p>
  </div>
  </div> 
    );
}

export default PerspectiveNavigation