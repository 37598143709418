import * as React from "react";
import Header_icon from "../images/Header_icon.png";
import Ellipse_icon from "../images/Ellipse_icon.png";
import Download_App from "../images/Download App.png";
import "./Header.css";
import {Link} from "gatsby"

const Header = () => {
  return (
    <div className="header">
      
      <div className="header__left">
      <Link className="logoLink" to="/">
        <img className="header__logo" src={Header_icon} alt="" />
        </Link>
      </div>
      
      <div className="header__right">
        <button className="download"> Download App</button>
      </div>
    </div>
  );
};

export default Header;
