import * as React from "react";
import "./BlogCard.css";
import { Link } from "gatsby"


const BlogCard = ({ image, title, paragraph, timestamp,slug }) => {
  return (
    <Link className="blogCardNavigator" to={`/Perspective/${slug}`}>
    <div className="blogCard">

      <img className="image" src={image} alt="" />

      <div className="blog__info">
        <div className="blog__text">
          <h3>{title}</h3>
          <p>{paragraph}</p>
        </div>
      
      </div>
      <div className="blogCard__footer">
          <p>{timestamp}</p>
          
          <h4 className="navigator" >Read more &nbsp; &rarr;</h4>
        
          
        </div>
    </div>
    </Link>
  );
};

export default BlogCard;


