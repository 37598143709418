import * as React from "react";
import Header from "./Header";
import "./Perspective.css";
import PerspectiveNavigation from "./PerspectiveNavigation";
import BlogCard from "./BlogCard"
import {Link} from "gatsby"
import {graphql} from "gatsby"
import Footer from "./Footer";


const Perspective = ({data}) => {
  const handleColorChange = () => {
    document.getElementById("perspective").style.background = "#0084F8";
    document.getElementById("perspective").style.color = "white";
    document.getElementById("trends").style.background = "white";
    document.getElementById("trends").style.color = "rgba(76, 95, 114, 1)";
  };

  const handleColorChangeTrends = () => {
    document.getElementById("trends").style.background = "#0084F8";
    document.getElementById("trends").style.color = "white";
    document.getElementById("perspective").style.background = "white";
    document.getElementById("perspective").style.color = "rgba(76, 95, 114, 1)";
  };

  return (
    <div className="app">
      <Header/>
      <PerspectiveNavigation />
      <div>
        <div className="button_div">
          <button id="perspective" onClick={handleColorChange} class="button ">
            Perspective
          </button>
          <Link   className="TrendsNavigator" to="/Trends">
          <button
            id="trends"
            onClick={handleColorChangeTrends}
            class="button2 "
          >
            &nbsp;&nbsp;&nbsp;Trends&nbsp;&nbsp;&nbsp;
          </button>
          </Link>
        </div>
      
       
      </div>

  {/* BlogCard */ }
  <div className="blog">

      {data.gcms.perspectivesBlogs.map((perspective) =>
      
      <BlogCard 
      image={perspective.coverImage.url}
      title={perspective.blogTitle}
      paragraph={perspective.cardDescription}
      timestamp={perspective.dateOfPublishing}
      slug={perspective.slug}
     
     />
      )}
        
      </div>
      <hr/>
      <Footer />
    </div>
  );
};

export default Perspective;


export const query= graphql`{
  gcms {
    perspectivesBlogs(stage: PUBLISHED) {
      authorName
      cardDescription
      dateOfPublishing
      slug
      blogContent {
        html
      }
      coverImage {
        url(transformation:{

        image:{
        resize:{
          width: 352,
          height:335
          fit:clip
        }
        }})
      }
      blogTitle
    }
  }
}`


