import * as React from "react";
import "./Footer.css"
import {Link} from "gatsby"
import FacebookIcon from "../images/facebook.png"
import InstagramIcon from "../images/Instagram.png"
import YoutubeIcon from "../images/youtube.png"
import TwitterIcon from "../images/Twitter.png"

const Footer = () => {
    return (
        <div>
            <div className="footer">
            <div className="footerRight">
                <p>© All rights reserved.© 2020-21 Copyright:  </p>
                <h3> Homzhub.com</h3>
            </div>
            <div className="footerLeft">
                <p>Terms &nbsp;&nbsp;&nbsp;</p>
                <p>Privacy</p>
            </div>
            </div>
            <div className="commonFooter">

                <p> Follow us on: </p>

                <div className="socia-media-links">
                    <Link className="facebook-icon-margin"
                        to="https://www.facebook.com/Homzhub/">
                        <img src={FacebookIcon} />
                    </Link>
                    <Link className="icon-margin" to="https://www.instagram.com/homzhub/">
                        <img src={InstagramIcon} />
                    </Link>
                    <Link className="icon-margin" to="https://twitter.com/homzhub?s=09 ">
                        <img src={TwitterIcon} />
                    </Link >
                    <Link className="icon-margin" to="https://www.youtube.com/watch?v=JE4I4I78Gl4 ">
                        <img src={YoutubeIcon} />
                    </Link >
                    <Link className="icon-margin" className="p_tag_social" to="https://www.linkedin.com/company/homzhubpropertymanagement ">
                        <p>in</p>
                    </Link>
                </div>

            </div>
            <div className="bottomFooter">
                <p>© All rights reserved.© 2020-21 Copyright:</p>
                <h2>Homzhub.com </h2>
            </div>
        </div>

    );

}

export default Footer